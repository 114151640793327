import React, { useEffect, useState } from "react";
import Menu from "./components/Navbar";
import Tilt from "react-tilt";
import About from "./components/About";
import Feature from "./components/Feature";
import ScreenShot from "./components/Screenshot";
import Team from "./components/Team";
import Blog from "./components/Blog";
import Price from "./components/Price";
import Testimonial from "./components/Testimonial";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import { API } from "./api handler/Api";



const HomeOne = () => {
  const [newdata, setnewdata] = useState([]);
  const [imgdata, setImgdata] = useState([]);
  useEffect(() => {
    getapidata()
    getapiimg()
  }, [])
  const getapidata = async () => {
    try {
      const { data } = await API.getTopSection();
      setnewdata(data.data);
    }
    catch (error) {
      console.log(error.message);
    }
  }
  const getapiimg = async () => {
    try {
      const { data } = await API.gettopimg();
      setImgdata(data.data);
    } catch (error) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  /*For changing background image by changing the color*/
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`
  });
  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/1.png)`
      })
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/1.png)`
      })
  }, [color]);
  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style");
  document.body.classList.remove("three");
  document.body.classList.remove("home-style-two");

  let datalist = newdata.map((post) => {
    return (
      <div key={post.id}>
        {/* <img src={`${process.env.PUBLIC_URL}/assets/images/logoimage.png`} alt="caption-img" className="caption-img" /> */}

        <h1 >
          {post.main_text}
        </h1>
        <p className={'descText'}>
          {post.meta_description}
        </p>
        {/* <a href={post.app_link_one} target="_blank"  >
          <img src={post.image_two} alt="appstore" className="store" />
        </a> */}
        <a href={post.app_link_two} target="_blank">
          <img
            className="ml-10 store"
            src={post.image_one}
            alt="play-store"
          />
        </a>
      </div>

    )
  })
  let apiimg = imgdata.map((post) => {
    return (
      <div key={post.id}>
        <img src={post.image_path} className="img-fluid" alt="" />
      </div>
    )


  })
  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-one" />
      {/* Home One Section Start */}
      <section id="home" className="home" style={bgImg}>
        <div className="home-decor">
          <div className="home-circle1">
            <img className="" src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`} alt="" />
          </div>
          <div className="home-circle2">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`} alt="" />
          </div>
          <div className="home-circle3">
            <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="home-contain">

                {datalist}

              </div>
            </div>
            <div className="col-sm-6 offset-md-1">
              <div className="home-right">
                <Tilt
                  options={{
                    perspective: 110,
                    speed: 400,
                    max: 1.2,
                    scale: 1,
                  }}
                >
                  {apiimg}
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home One Section End */}
      {/* About Component*/}
      <About />
      {/*Feature Component*/}
      <Feature />
      {/*ScreenShot Component*/}
      <ScreenShot />
      {/*Team Component*/}
      {/* <Team /> */}
      {/*Blog Component*/}
      {/* <Blog /> */}
      {/*Price Component*/}
      {/* <Price /> */}
      {/*Testimonial Component*/}
      <Testimonial />
      {/*Contact Component*/}
      <Contact />
      {/*Subscription Component*/}
      {/* <Subscribe /> */}
      {/*Footer Component*/}
      <Footer />

    </div>
  );
};

export default HomeOne;

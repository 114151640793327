import {request} from './APIHandler';



export const API = {

  getTopSection: () => request.get(`get-top-section`),
  gettopimg: () => request.get(`get-media-file?item_type=Head`),
  getabout: () => request.get(`get-about`),
  getaboutimg: () => request.get(`get-media-file?item_type=About`),
  getfeature: () => request.get(`get-feature`),
  getfeatureimg: () => request.get(`get-media-file?item_type=Feature`),
  getscreenshort: () => request.get(`get-screen-shot`),
  getteam: () => request.get(`all-team`),
  gettestimonial: () => request.get(`get-review`),
  gettestimonial: () => request.get(`get-review`),
  getcontactimg: () => request.get(`get-media-file?item_type=User`),
  getsetting: () => request.get(`get-setting`),

  postform: body => request.post('contact-us', body),
};

import React, { useEffect, useState, useRef } from "react";
import { API } from "../api handler/Api";

const Contact = () => {

  const form = useRef();
  const sendEmail = async (e) => {
    e.preventDefault();
    e.persist()
    const full_name = e.target.full_name.value;
    const first_name = e.target.first_name.value;
    const email = e.target.email.value;
    const message = e.target.message.value;
    const body = { full_name, first_name, email, message };
    try {
      const { data } = await API.postform(body);
      console.log(data);
      e.target.reset();
    } catch (error) {
      console.log(error.message);
    }
  };
 

  const [imgdata, setImgdata] = useState([]);
  useEffect(() => {
    getapiimg()
  }, [])

  const getapiimg = async () => {
    try {
      const { data } = await API.getcontactimg();
      setImgdata(data.data);
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`
  });

  const color = localStorage.getItem("color");

  useEffect(() => {
    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/contact.png)`
      })
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/contact.png)`
      })
  }, [color]);

  let apiimg = imgdata.map((post) => {
    return (
      <div className="contact-right" key={post.id}>
        <img
          src={post.image_path}
          className="img-fluid"
          alt=""
        />
      </div>
    )
  })
  return (
    <section id="contact" className="contact" style={bgImg}>
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src="assets/images/main-banner12.png" alt="" />
        </div>
        <div className="contact-circle2">
          <img src="assets/images/main-banner1.png" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title">
                  <span>contact </span>information
                </h2>
                <form className="theme-form" ref={form} onSubmit={sendEmail}>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="your name"
                          required="required"
                          name="full_name"
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="phone"
                          required="required"
                          name="first_name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="email address"
                      required="required"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="message"
                      required="required"
                      name="message"
                    ></textarea>
                  </div>
                  <div className="form-button">
                    <button
                      type="submit"
                      className="btn btn-custom theme-color"
                    >
                      send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-medium-none-lg">
            {apiimg}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import { API } from "../api handler/Api";

const About = () => {

  const [newdata, setNewdata] = useState([]);
  const [imgdata, setImgdata] = useState([]);


  useEffect(() => {
    getapidata()
    getapiimg()
  }, [])

  const getapidata = async () => {
    try {
      const { data } = await API.getabout();
      setNewdata(data.data);

    } catch (error) {
      console.log(error.message);
    }
  }
  const getapiimg = async () => {
    try {
      const { data } = await API.getaboutimg();
      setImgdata(data.data);
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
  });

  const color = localStorage.getItem("color");
  useEffect(() => {

    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/about-bg.png)`
      })
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png)`
      })
  }, [color]);

  let apiimg = imgdata.map((post) => {
    return (
      <div className="about-phone" key={post.id}>
        <img src={post.image_path} className="img-fluid" alt="aboutus"  />
      </div>
    )
  })

  const Datalist = newdata.map((post) => {
    return (
      <div className="container" key={post.id}>
        <div className="row ">
          <div className="col-md-5">
            <div className="about-contain">
              <div className="for-width">
                <h2 className="title">
                  about<span> {post.title}</span>
                </h2>
                <p className="caption-about descText">
                  {post.meta_description}
                </p>
                <div className="row sm-mb">

                  {post.attribute.map((att) => (
                    <div className="col-6" key={att.id}>
                      <ul className="about-style  " >
                        <li className="abt-hover" >
                          <div className="about-icon">
                            <div className="icon-hover">
                              <img src={att.image} alt="images" style={{height:25,width:25}} />
                            </div>
                          </div>
                          <div className="about-text ">
                            <h3>{att.attribute}</h3>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
                {/* <div className="top-margin">
                  <button
                    type="button"
                    className="btn btn-custom theme-color theme-color"
                  >
                    view more
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-7 d-medium-none">
            <div className="about-right">

              {apiimg}

            </div>
          </div>
        </div>
      </div>

    );
  });


  return (
    <section id="about" className="about" style={bgImg}>
      <div className="about-decor">
        <div className="about-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="banner1" />
        </div>
      </div>
      <div>
        {Datalist}
      </div>


    </section>
  );
}


export default About;

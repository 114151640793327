import React, { useEffect, useState } from "react";
import { API } from "../api handler/Api";

const Feature = () => {

  const [newdata, setNewdata] = useState([]);
  const [imgdata, setImgdata] = useState([]);
  
  useEffect(() => {
    getapidata()
    getapiimg()
  }, [])

  const getapidata = async () => {
    try {
      const { data } = await API.getfeature();
      setNewdata(data.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  const getapiimg = async () => {
    try {
      const { data } = await API.getfeatureimg();
      setImgdata(data.data);
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
  });

  const color = localStorage.getItem("color");
  useEffect(() => {

    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
      });
    else if (color === "color-2")
      setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/feature-bg1.png)` })
    else
      setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)` })
  }, [color]);


  let datalist = newdata.map((post) => {
    return (
      <div className="col-12 col-md-6" key={post.id}>
        <ul className="feature-style">
          <li>
            <div className="feature-icon">
              <img src={post.feature_image} alt="icon"  style={{height:32,width:32}} />
            </div>
            <div className="feature-subtitle">
              <h3>{post.title}</h3>
            </div>
            <div>
              <p className="descText">
                {post.meta_description}{" "}
              </p>
            </div>
          </li>
        </ul>
      </div>
    )
  })

  let apiimg = imgdata.map((post) => {
    return (
      <div key={post.id}>
        <img src={post.image_path}  className="img-fluid" alt=""/>
      </div>
    )
  })

  return (
    <section id="feature" className="feature" style={bgImg}>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="feature-phone">
            {apiimg}
          </div>
          <div className="offset-lg-4 col-lg-8" >
            <div className="row">
              <div className="col-sm-12 mrgn-md-top">
                <h2 className="title">
                  features of<span> DWIVE ST LUCIA</span>
                </h2>
              </div>

              {datalist}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;

import React, { useEffect,useState } from "react";
import { API } from "../api handler/Api";

const Footer = () => {


  const [newdata, setnewdata] = useState([]);

  useEffect(() => {
    getapidata()
  }, [])

  const getapidata = async () => {
    try {
      const  {data}  = await API.getsetting();
      setnewdata(data.data);
      
    }
    catch (error) {
      console.log(error.message);
    }
  }
 
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  // Copyright date
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div>
      <section className="p-0">
        <div className="container-fluid">
          <div className="bottom-section">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="address-bar">

                  
                  <div style={{padding:30,}}>
                    <ul className="footer-style">
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/locate.png`} alt="locate" style={{width:24,height:24}} />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          {newdata.address}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/telephone.png`} alt="telephone" style={{width:24,height:24}} />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e) => { e.preventDefault() }}>{newdata.phone_number}</a>
                        </div>
                      </li>
                      <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fotter-email.png`} alt="fotter-email" style={{width:24,height:24}} />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e) => { e.preventDefault() }}>{newdata.email}</a>
                        </div>
                      </li>
                      {/* <li>
                        <div className="footer-icon">
                          <img src={`${process.env.PUBLIC_URL}/assets/images/fax.png`} alt="" />
                        </div>
                        <div className="footer-address">
                          <a href="javascript" onClick={(e) => { e.preventDefault() }}>{newdata.}</a>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.159387417562!2d70.32458511440184!3d28.414447100670067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39375dc5b7615691%3A0x7c5fdfe4696fb00f!2sSemantic%20Solutions!5e0!3m2!1sen!2s!4v1677820086200!5m2!1sen!2s"



                  className="md-height">
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="copyright-section index-footer">
        <p>© 2021 - {getCurrentYear()} Dweevay. All rights reserved.</p>
      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;

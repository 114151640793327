import axios from 'axios';

let userToken = null;
export const staging = true;
const STAGING_URL = 'https://admin.dweevay.com/api/';
const PRODUCTION_URL = 'https://admin.dweevay.com/api/';
export const BASE_URL = staging ? STAGING_URL : PRODUCTION_URL;

export const request = axios.create({
  baseURL: BASE_URL,
  headers: {
     Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  
});

request.interceptors.request.use(
  async config => {
    return config;
  },
  error => Promise.reject(error),
);

request.interceptors.response.use(
  response => response,
  async error => {
    const {config, response} = error;
    if (
      response?.data?.error === 'Unauthenticated' ||
      response?.status == 401
    ) {
      try {
        console.log('API Response Unauthenticated');
        // await refreshToken();
        // userToken = null;
        // return request(config);
      } catch (err) {
        console.log('API => Interceptor => Response');
        console.log(err);
      }
    }
    return Promise.reject(error);
  },
);

// export const refreshToken = async () => {
//   try {
//     const userCredentials = await getFromLocal(storageKeys.userCredentials);
//     const { username, password } = userCredentials;
//     const loginEmailRes = await loginWithEmail(userCredentials);
//     const { data } = loginEmailRes;
//     await saveToLocal(storageKeys.userToken, data);
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

// export const getFormData = object => {
//   const formData = new FormData();
//   for (let key in object) {
//     formData.append(key, object[key]);
//   }
//   return formData;
// };
